
import {Component, Vue, Prop} from 'vue-property-decorator';
import MissionPresentation from "@/views/MissionPresentation.vue";
import {Action, Getter} from "vuex-class";
import {IMissionDB, StoreAction} from "@/types";
import {ROUTES} from "@/data";

@Component({
    name: "Presentation",
    components: {MissionPresentation}
})
export default class Presentation extends Vue {

    @Action('actions/loadPresentationMission') loadPresentationMission!: StoreAction;

    loading = false;
    globalErrors:any = [];
    mission: IMissionDB | null = null

    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;

    get token() {
        return this.$route.params.token;
    }

    get missionName() {
        if (this.mission) {
            // if (this.isAgency) {
            //     if (!this.mission.name) {
            //         if (this.mission?.client?.name) {
            //             return this.mission.client.name
            //         }
            //     }
            //     return this.mission.name;
            // }
            // if (this.isCompany) {
                if (!this.mission.agencyId) {
                    return this.$t('layout.left.newOrder');
                }
                return this.mission.agency?.name || this.$t('layout.left.newOrder');
            // }
            // return this.$t('layout.left.newOrder');
        }
    }

    async init() {
        this.loading = true;
        try {
            this.mission = await this.loadPresentationMission(this.token);
        } catch (e) {
            console.log(e);
            this.globalErrors = e._global;
        }
        this.loading = false;
    }

    goToRegisterCompany() {
        this.$router.push({name: ROUTES.REGISTER_COMPANY});
    }

    missionChanged(mission:any) {
        this.mission = mission;
        this.$forceUpdate();
    }

    beforeMount() {
        this.init();
    }
}
